import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import ThemeContext from "../context/theme-context"

import SEO from "../components/seo"
import Button from "../components/button"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Sed mi et euismod luctus nulla urna"

    return (
      <ThemeContext.Consumer>
        {theme => (
          <div>
            <SEO
              title="Home"
              keywords={[`blog`, `gatsby`, `javascript`, `react`]}
            />
            <H1 theme={theme}>
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 12 7"
                style={{ enableBackground: "new 0 0 12 7" }}
                xmlSpace="preserve"
              >
                <path
                  shapeRendering="crispEdges"
                  style={{ fill: theme.primaryColor[2] }}
                  d="M7,0h2v2h1V0h2v2h-1v1h-1v1h1v3H9V5H8v2H6V5h1V4h1V3H7V0z M4,3v1h1v3H0V5h1V4h1V3
	h1V1H2v1H0V1h1V0h4v3H4z M3,5H2v1h1V5z"
                />
              </svg>
              Braxtronix
            </H1>
            <p>
              Welcome to your new Gatsby website. You are on your home page.
            </p>
            <p>
              This starter comes out of the box with{" "}
              <strong>styled components</strong> and Gatsby's default starter
              blog running on <em>Netlify Content Management System</em>.
            </p>
            <p>Now go build something great!</p>
            <Link to="/blog/">
              <Button marginTop="35px">Blog</Button>
            </Link>
          </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

const H1 = styled.div`
  margin: 0;
  padding: 0;
  font-size: ${props => props.theme.fontSize * 4 + props.theme.unit};
  line-height: ${props => props.theme.fontSize * 4 + props.theme.unit};
  img,
  svg {
    height: ${props => props.theme.spacer * 7 + props.theme.unit};
    margin: 0 0 0 0;
  }
`

export default IndexPage
